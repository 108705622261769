import {React} from 'react';

export default function header () {
    return (
        <header className="header">
            <div className="container">
                <img className="logo" src="/images/logo-immo-gestion-ile-reunion-couleur.svg" alt="Immo Gestion" />
            </div>
        </header>
    )
}